.container-cards {
      gap: 28px;
      margin-top: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      @media screen and (max-width: 685px) {
          flex-direction: column;
      }
  
      .card {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 28px;
          padding: 50px 52px 76px 52px;
          width: 572px;
          height: 305px;
  
          @media screen and (max-width: 1420px) {
              width: 530px;
          }
  
          @media screen and (max-width: 1340px) {
              width: 480px;
          }
  
          @media screen and (max-width: 1260px) {
              width: 410px;
              height: 220px;
          }
  
          @media screen and (max-width: 1180px) {
              width: 330px;
              height: 195px;
              padding: 30px 32px 40px 32px;
          }
  
          @media screen and (max-width: 1180px) {
              width: 290px;
              height: 170px;
          }
  
          @media screen and (max-width: 880px) {
              width: 270px;
              height: 160px;
          }
  
          @media screen and (max-width: 770px) {
              width: 240px;
              height: 150px;
          }
  
          @media screen and (max-width: 685px) {
              width: 450px;
              height: 220px;
          }
  
          @media screen and (max-width: 568px) {
              width: 365px;
              height: 200px;
          }
  
          @media screen and (max-width: 490px) {
              width: 340px;
              height: 190px;
          }
  
          @media screen and (max-width: 460px) {
              width: 285px;
              height: 150px;
          }
  
          @media screen and (max-width: 400px) {
              width: 226px;
              height: 130px;
          }
  
          @media screen and (max-width: 340px) {
              width: 195px;
              height: 110px;
          }
  
          h3 {
              font-family: "Inter", sans-serif;
              font-size: 22px;
              align-self: flex-start;
              justify-self: flex-start;
              letter-spacing: -0.025em;
              font-weight: 600;
              line-height: 1.2;
              margin-bottom: 2px;
  
              @media screen and (max-width: 770px) {
                  font-size: 16px;
              }
  
              @media screen and (max-width: 685px) {
                  font-size: 20px;
              }
  
              @media screen and (max-width: 460px) {
                  font-size: 18px;
              }
  
              @media screen and (max-width: 400px) {
                  font-size: 16px;
              }
  
              @media screen and (max-width: 340px) {
                  font-size: 14px;
              }
          }
  
          .clean {
              margin-bottom: 16px;
          }
  
          p {
              font-family: "Inter", sans-serif;
              align-self: flex-start;
              margin-bottom: 22px;
              letter-spacing: -0.025em;
  
              @media screen and (max-width: 880px) {
                  font-size: 12px;
              }
  
              @media screen and (max-width: 685px) {
                  font-size: 15px;
              }
  
              @media screen and (max-width: 400px) {
                  font-size: 11px;
              }
          }
  
          img {
              -webkit-user-drag: none;
              -khtml-user-drag: none;
              -moz-user-drag: none;
              -o-user-drag: none;
  
              @media screen and (max-width: 1340px) {
                  width: 500px;
              }
  
              @media screen and (max-width: 1260px) {
                  width: 440px;
              }
  
              @media screen and (max-width: 1180px) {
                  width: 300px;
              }
  
              @media screen and (max-width: 880px) {
                  width: 280px;
              }
  
              @media screen and (max-width: 770px) {
                  width: 270px;
              }
  
              @media screen and (max-width: 685px) {
                  width: 410px;
              }
  
              @media screen and (max-width: 568px) {
                  width: 360px;
              }
  
              @media screen and (max-width: 460px) {
                  width: 280px;
              }
  
              @media screen and (max-width: 400px) {
                  width: 228px;
              }
  
              @media screen and (max-width: 340px) {
                  width: 195px;
              }
          }
  
          .card-content {
              display: flex;
              gap: 40px;
              align-items: flex-start;
              justify-content: center;
              margin-top: 50px;
  
              @media screen and (max-width: 1180px) {
                  gap: 20px;
                  margin-top: 20px;
              }
  
              @media screen and (max-width: 685px) {
                  gap: 30px;
                  margin-top: 32px;
              }
  
              @media screen and (max-width: 460px) {
                  gap: 18px;
                  margin-top: 15px;
              }
  
              .perf,
              .acc {
                  @media screen and (max-width: 1340px) {
                      width: 82px !important;
                  }
  
                  @media screen and (max-width: 1180px) {
                      width: 64px !important;
                  }
  
                  @media screen and (max-width: 1020px) {
                      width: 60px !important;
                  }
  
                  @media screen and (max-width: 880px) {
                      width: 52px !important;
                  }
  
                  @media screen and (max-width: 685px) {
                      width: 86px !important;
                  }
  
                  @media screen and (max-width: 568px) {
                      width: 71px !important;
                  }
  
                  @media screen and (max-width: 460px) {
                      width: 60px !important;
                  }
  
                  @media screen and (max-width: 400px) {
                      width: 50px !important;
                  }
  
                  @media screen and (max-width: 340px) {
                      width: 44px !important;
                  }
              }
  
              .acc {
                  width: 105px;
              }
  
              .perf {
                  width: 105px;
              }
  
              .best,
              .seo {
                  @media screen and (max-width: 1340px) {
                      width: 72px !important;
                  }
  
                  @media screen and (max-width: 1180px) {
                      width: 55px !important;
                  }
  
                  @media screen and (max-width: 1020px) {
                      width: 52px !important;
                  }
  
                  @media screen and (max-width: 880px) {
                      width: 45px !important;
                  }
  
                  @media screen and (max-width: 685px) {
                      width: 74px !important;
                  }
  
                  @media screen and (max-width: 568px) {
                      width: 62px !important;
                  }
  
                  @media screen and (max-width: 460px) {
                      width: 50px !important;
                  }
  
                  @media screen and (max-width: 400px) {
                      width: 42px !important;
                  }
  
                  @media screen and (max-width: 340px) {
                      width: 37px !important;
                  }
              }
  
              .best {
                  width: 92px;
              }
  
              .seo {
                  width: 92px;
              }
          }
      }
  }
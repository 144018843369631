body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

html, body {
  overflow-x: hidden;
}

* {
  cursor: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #ccc;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #3f3f3f;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #1d1d1f
}
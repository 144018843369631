.contact-form-container {
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      padding: 18px;
  
      .field {
          outline: none;
          border-radius: 6px;
          padding: 10px 14px;
          font-family: "Inter", sans-serif;
          letter-spacing: -0.025em;
          font-size: 14px;
          transition: 0.2s border;
  
          &:focus {
              border: 2px solid #0064ff;
          }
      }
  
      .data {
          @media screen and (max-width: 810px) {
              width: 155px;
          }
  
          @media screen and (max-width: 730px) {
              width: 130px;
          }
          
          @media screen and (max-width: 685px) {
              width: 200px;
          }
  
          @media screen and (max-width: 560px) {
              width: 170px;
          }
  
          @media screen and (max-width: 500px) {
              width: 130px;
          }
  
          @media screen and (max-width: 425px) {
              width: 115px;
          }
      }
  
      .submit {
          background-color: #0064ff;
          color: white;
          padding: 12px 0;
          border: none;
          border-radius: 10px;
          transition: 0.2s all;
          font-family: "Inter", sans-serif;
          font-size: 15px;
          width: 100%;
  
          &:hover {
              background-color: #004fc5;
          }
  
          &:active {
              transform: scale(0.98);
          }
      }
  
      textarea {
          width: 440px;
          min-width: 440px;
          max-width: 440px;
          max-height: 225px;
          min-height: 225px;
          margin-bottom: 16px;
          margin-top: 10px;
  
          @media screen and (max-width: 810px) {
              width: 350px;
              min-width: 350px;
              max-width: 350px;
          }
  
          @media screen and (max-width: 730px) {
              width: 300px;
              min-width: 300px;
              max-width: 300px;
          }
  
          @media screen and (max-width: 685px) {
              width: 440px;
              min-width: 440px;
              max-width: 440px;
          }
  
          @media screen and (max-width: 560px) {
              width: 380px;
              min-width: 380px;
              max-width: 380px;
          }
  
          @media screen and (max-width: 500px) {
              width: 300px;
              min-width: 300px;
              max-width: 300px;
          }
          
          @media screen and (max-width: 425px) {
              width: 270px;
              min-width: 270px;
              max-width: 270px;
          }
      }
  
      .input-container {
          display: flex;
          gap: 10px;
      }
  }
.resume-container {
      display: flex;
      flex-direction: column;
      width: 100vw;
      padding-top: 30px;
      padding-bottom: 50px;
      background-color: white;
      min-height: 100vh;
      align-items: center;
      margin-left: 0;
      align-items: center;
      position: relative;
  
      @media screen and (max-width: 690px) {
          margin-bottom: 0px;
      }
  
      @media screen and (max-width: 540px) {
          min-height: 50vh;
      }
  
      #resume {
          position: absolute;
          top: 16px;
      }
  
      h3,
      h1 {
          margin-left: 0;
          z-index: 5;
      }
  
      h3 {
          margin-left: 0;
          margin-bottom: 6px;
      }
  
      h1 {
          margin-left: 0;
          text-align: center;
          margin-bottom: 79px;
          font-size: 92px;
          font-weight: 700;
          background: rgb(38,164,239);
          background: linear-gradient(315deg, rgba(38,164,239,1) 0%, rgba(39,164,239,1) 100%);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
  
          @media screen and (max-width: 810px) {
              font-size: 82px;
              max-width: 650px;
          }
  
          @media screen and (max-width: 690px) {
              font-size: 72px;
              max-width: 600px;
              margin-bottom: 50px;
          }
          
          @media screen and (max-width: 620px) {
              font-size: 60px;
              max-width: 500px;
              margin-bottom: 50px;
          }
  
          @media screen and (max-width: 540px) {
              font-size: 54px;
              max-width: 450px;
              margin-bottom: 10px;
          }
  
          @media screen and (max-width: 470px) {
              font-size: 48px;
              max-width: 400px;
              margin-bottom: 0px;
          }
  
          @media screen and (max-width: 420px) {
              font-size: 40px;
              max-width: 330px;
              margin-bottom: -40px;
          }
  
          @media screen and (max-width: 368px) {
              font-size: 34px;
              max-width: 270px;
              margin-bottom: -40px;
          }
      }
  
      .resume-box {
          border: none;
          z-index: 8;
          margin-right: 4px;
          border-radius: 28px;
          background: rgb(30,208,252);
          background: linear-gradient(300deg, rgba(30,208,252,1) 0%, rgba(2,98,224,1) 100%);
          width: 731px;
          height: 372px;
          align-items: center;
          display: flex;
  
          @media screen and (max-width: 770px) {
              scale: 0.9;
          }
  
          @media screen and (max-width: 690px) {
              scale: .8;
          }
  
          @media screen and (max-width: 620px) {
              scale: 0.69;
          }
  
          @media screen and (max-width: 540px) {
              scale: 0.6;
          }
  
          @media screen and (max-width: 470px) {
              height: 500px;
              width: 350px;
              margin-top: 100px;
              scale: 1;
          }
  
          @media screen and (max-width: 368px) {
              width: 295px;
              height: 500px;
              scale: 1;
          }
  
          .resume-box-left {
              align-self: flex-end;
              position: relative;
  
              img {
                  max-height: 372px;
                  position: absolute;
                  bottom: 0px;
                  -webkit-filter: drop-shadow(0px 0px 2px #222222);
                  filter: drop-shadow(0px 0px 2px #222222);
  
                  @media screen and (max-width: 470px) {
                      left: 60px;
                      max-height: 212px;
                  }
  
                  @media screen and (max-width: 368px) {
                      left: 30px;
                  }
              }
          }
  
          .resume-box-right {
              max-width: 290px;
              margin-left: 51.5%;
              display: flex;
              flex-direction: column;
              align-items: center;
  
              @media screen and (max-width: 470px) {
                  margin: 0 auto;
                  margin-bottom: 185px;
              }
  
              a {
                  text-decoration: none;
                  color: transparent;
              }
  
              p {
                  margin-bottom: 42px;
                  font-family: "Inter", sans-serif;
                  color: white;
                  text-align: center;
  
                  @media screen and (max-width: 360px) {
                      font-size: 14px;
                      max-width: 225px;
                  }
              }
  
              button {
                  border-radius: 24px;
                  width: 198px;
                  border: none;
                  padding: 15px 22px;
                  box-sizing: border-box;
                  height: 52px !important;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  padding-left: 22px;
                  gap: 8px;
                  font-family: "Inter", sans-serif;
                  color: white;
                  font-weight: 500;
                  font-size: 17.35px;
                  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  
                  .svg {
                      height: 24px;
                      width: 24px;
                  }
              }
  
              .download {
                  background-color: #0064ff;
                  margin-bottom: 8px;
  
                  &:hover {
                      .download-svg {
                          margin-top: 6px;
                      }
                  }
  
                  .download-svg {
                      fill: white;
                      transition: 0.225s ease-in-out all;
                  }
              }
  
              .contact {
                  background-color: white;
                  color: #0064ff;
                  transition: 0.3s all;
  
                  &:hover {
                      background-color: #0064ff;
                      color: white;
  
                      .contact-svg {
                          fill: white;
                      }
                  }
  
                  .contact-svg {
                      fill: #0064ff;
                      transition: 0.3s all;
                  }
              }
          }
      }
  }
@keyframes gradient-animation {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
  }
  
  .container-manifest {
      display: flex;
      align-items: center;
      width: 100vw;
      justify-content: center;
      height: 60vh;
  
      @media screen and (max-width: 450px) {
        height: 30vh;
      }
  
      .manifest {
          background: linear-gradient(300deg,#ffee07,#ff2b6a,#ab4bff,#5ca2ff,#df1700);
          background-size: 360% 360%;
          animation: gradient-animation 30s ease infinite;
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 90px;
  
          @media screen and (max-width: 598px) {
            margin-bottom: 0px;
          }
  
          @media screen and (max-width: 450px) {
            margin-bottom: 90px;
          }
  
          h1 {
              font-family: "Inter", sans-serif;
              font-size: 83px;
              line-height: 1.05;
              letter-spacing: -0.04em;
              max-width: 1200px;
              text-align: center;
  
              @media screen and (max-width: 1210px) {
                font-size: 72px;
                max-width: 1100px;
              }
  
              @media screen and (max-width: 1040px) {
                font-size: 62px;
                max-width: 950px;
              }
  
              @media screen and (max-width: 880px) {
                font-size: 52px;
                max-width: 700px;
              }
  
              @media screen and (max-width: 750px) {
                font-size: 46px;
                max-width: 650px;
              }
  
              @media screen and (max-width: 690px) {
                font-size: 40px;
                max-width: 575px;
              }
  
              @media screen and (max-width: 598px) {
                font-size: 34px;
                max-width: 500px;
              }
  
              @media screen and (max-width: 490px) {
                font-size: 30px;
                max-width: 400px;
              }
  
              @media screen and (max-width: 432px) {
                font-size: 26px;
                max-width: 350px;
              }
  
              @media screen and (max-width: 386px) {
                font-size: 24px;
                max-width: 350px;
              }
  
              @media screen and (max-width: 350px) {
                font-size: 26px;
                max-width: 300px;
              }
          }
      }
  }   
.projects-container {
      background-color: white;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100vw;
      padding-bottom: 142px;
  
      #portfolio {
          position: absolute;
          top: 0;
      }
  
      .more {
          color: #006DDB;
          align-self: center;
          margin-top: 70px;
          font-size: 20px;
          font-family: "Inter", sans-serif;
          font-weight: 500;
          text-decoration: none;
          transition: 0.15s all;
  
          &:hover {
              scale: 1.05;
          }
      }
  
      h1 {
          max-width: 860px;
          margin-bottom: 140px;
      }
  
      h3 {
          margin-bottom: 16px;
          letter-spacing: -0.035em;
      }
  
      .project-list {
          align-self: center;
          display: flex;
          justify-content: center;
          gap: 18px;
          max-width: 1400px;
          flex-wrap: wrap;
  
          img {
              max-width: 690px;
              height: 365px;
              flex-wrap: wrap;
  
              @media screen and (max-width: 1440px) {
                  max-width: 600px;
                  height: 317px;
              }
  
              @media screen and (max-width: 1280px) {
                  max-width: 550px;
                  height: 290px;
              }
  
              @media screen and (max-width: 1205px) {
                  max-width: 500px;
                  height: 264px;
              }
  
              @media screen and (max-width: 1080px) {
                  max-width: 450px;
                  height: 238px;
              }
  
              @media screen and (max-width: 970px) {
                  max-width: 900px;
                  height: 476px;
              }
  
              @media screen and (max-width: 950px) {
                  max-width: 800px;
                  height: 423px;
              }
  
              @media screen and (max-width: 840px) {
                  max-width: 700px;
                  height: 370px;
              }
  
              @media screen and (max-width: 740px) {
                  max-width: 600px;
                  height: 317px;
              }
  
              @media screen and (max-width: 640px) {
                  max-width: 500px;
                  height: 264px;
              }
  
              @media screen and (max-width: 540px) {
                  max-width: 460px;
                  height: 243px;
              }
  
              @media screen and (max-width: 490px) {
                  max-width: 390px;
                  height: 206px;
              }
  
              @media screen and (max-width: 420px) {
                  max-width: 340px;
                  height: 180px;
              }
  
              @media screen and (max-width: 360px) {
                  max-width: 290px;
                  height: 154px;
              }
          }
      }
  }
  
  a {
      position: relative;
  
      ::after {
          background-color: rgba(0, 0, 0, 0.8);
          width: 100px;
          height: 26px;
          border-radius: 12px;
          color: white;
          font-family: "Inter", sans-serif;
          padding: 4px;
          left: 40%;
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 30;
          position: absolute;
          transition: 0.2s all;
      }
  
      &:hover {
          ::after {
              opacity: 1;
          }
      }
  }
  
  .ecom {
      ::after {
          content: "Project 1";
          width: 210px;
      }
  }
  
  .cv {
      ::after {
          content: "Project 2";
      }
  }
  
  .reddit {
      ::after {
          content: "Project 3";
          width: 110px;
      }
  }
  
  .mac {
      ::after {
          content: "Project 4";
          width: 190px;
      }
  }
  
  .ecom,
  .cv {
      ::after {
          top: -30px;
      }
  
      &:hover {
          ::after {
              top: -45px;
          }
      }
  }
  
  .reddit,
  .mac {
      ::after {
          bottom: -30px;
      }
  
      &:hover {
          ::after {
              bottom: -45px;
          }
      }
  }
.contact-container {
      width: 100vw;
      padding-bottom: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
  
      #contact {
          position: absolute;
          top: 26px;
      }
  
      h1 {
          max-width: 700px;
          font-size: 84px;
          text-align: center;
  
          @media screen and (max-width: 810px) {
              font-size: 72px;
              max-width: 600px;
          }
  
          @media screen and (max-width: 685px) {
              font-size: 60px;
              max-width: 500px;
          }
  
          @media screen and (max-width: 560px) {
              font-size: 52px;
              max-width: 400px;
              margin-left: 30px !important;
          }
  
          @media screen and (max-width: 560px) {
              font-size: 44px;
              max-width: 370px;
              margin-left: 30px !important;
          }
  
          @media screen and (max-width: 500px) {
              max-width: 300px;
              font-size: 40px;
          }
      }
  
      h1,
      h3 {
          margin-left: 0;
      }
  
      h3 {
          padding-top: 150px;
      }
  
      p {
          text-align: center;
          margin-top: 45px;
          font-size: 16px;
          max-width: 720px;
          font-family: "Inter", sans-serif;
          letter-spacing: -0.025em;
  
          @media screen and (max-width: 810px) {
              max-width: 600px;
          }
  
          @media screen and (max-width: 685px) {
              max-width: 500px;
              font-size: 14px;
          }
  
          @media screen and (max-width: 560px) {
              max-width: 420px;
              font-size: 14px;
          }
  
          @media screen and (max-width: 500px) {
              max-width: 380px;
              font-size: 14px;
          }
  
          @media screen and (max-width: 425px) {
              max-width: 310px;
              font-size: 13px;
          }
      }
  
      .contact-data {
          margin-top: 44px;
          display: flex;
          gap: 22px;
  
          @media screen and (max-width: 685px) {
              flex-direction: column;
          }
  
          .contact-socials-container {
              display: flex;
              flex-direction: column;
              border-radius: 20px;
              padding: 22px 24px;
              box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.2);
              gap: 10px;
  
              @media screen and (max-width: 685px) {
                  align-items: center;
              }
  
              a {
                  text-decoration: underline;
                  color: transparent;
              }
  
              button {
                  border: none;
                  padding: 16px 14px;
                  display: flex;
                  font-family: "Inter", sans-serif;
                  font-weight: 600;
                  font-size: 15px;
                  align-items: center;
                  gap: 18px;
                  letter-spacing: -0.025em;
                  border-radius: 10px;
                  width: 200px;
                  transition: 0.25s all;
  
                  @media screen and (max-width: 685px) {
                      width: 460px;
                  }
  
                  @media screen and (max-width: 560px) {
                      width: 400px;
                  }
  
                  @media screen and (max-width: 500px) {
                      width: 322px;
                  }
  
                  @media screen and (max-width: 425px) {
                      width: 292px;
                  }
  
                  .svg {
                      width: 22px;
                      height: 21px;
                  }
  
                  .light-svg {
                      fill: #383838;
                  }
  
                  .dark-svg {
                      fill: #727272;
                  }
  
                  &:active {
                      transform: scale(0.95);
                  }
  
                  &:hover {
                      color: white;
  
                      .svg {
                          fill: white;
                      }
                  }
              }
  
              .linkedin {
                  &:hover {
                      background: rgb(89,154,193);
                      background: linear-gradient(315deg, rgba(89,154,193,1) 0%, rgba(19,118,179,1) 100%);
                  }
              }
  
              .github {
                  &:hover {
                      background: rgb(197,155,251);
                      background: linear-gradient(315deg, rgba(197,155,251,1) 0%, rgba(112,83,148,1) 100%);
                  }
              }
  
              .twitter {
                  &:hover {
                      background: rgb(50,110,254);
                      background: linear-gradient(315deg, rgba(50,110,254,1) 0%, rgba(1,170,238,1) 100%);
                  }
              }
  
              .whatsapp {
                  &:hover {
                      background: rgb(12,144,34);
                      background: linear-gradient(315deg, rgba(12,144,34,1) 0%, rgba(50,241,82,1) 100%);
                  }
  
                  .svg {
                      transition: 0.25s all;
                  }
              }
  
              .email {
                  margin-top: 46px;
                  background-color: #0064ff;
                  color: white;
                  display: flex;
                  justify-content: center;
                  align-self: flex-end;
  
                  @media screen and (max-width: 685px) {
                      margin-top: 28px;
                  }
  
                  &:hover {
                      background-color: #004fc5;
                  }
              }
          }
      }
  }